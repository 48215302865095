// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-imprint-js": () => import("./../src/templates/imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */),
  "component---src-templates-conditions-js": () => import("./../src/templates/conditions.js" /* webpackChunkName: "component---src-templates-conditions-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-pages-de-404-js": () => import("./../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-en-404-js": () => import("./../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */)
}

